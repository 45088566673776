import React, { useRef, useEffect } from "react";
import Page from "./Page";
import "./Home.less";
import computer from "../images/computer.png";

const Home = ({ show }) => {
  const bgRef = useRef();
  const scrollRef = useRef();
  const moveRef = useRef();
  let x = 0;
  const bgAct = () => {
    return setInterval(() => {
      x += 1;
      if (x >= 1000) {
        x = 0;
      }
      bgRef.current.style.backgroundPositionX = `${x}%`;
    }, 40);
  };
  useEffect(() => {
    const listener = bgAct();

    return () => {
      clearInterval(listener);
    };
  }, []);

  let left = 0;
  const onWheel = e => {
    if (e.deltaY > 0) {
      left -= 100;
      if (left < -500) {
        left = -500;
      }
    } else {
      left += 100;
      if (left > 0) {
        left = 0;
      }
    }
    if (left >= -500 && left <= 0) {
      moveRef.current.style.marginLeft = `${left}vw`;
    }
  };
  return (
    <Page show={show}>
      <div
        className="page mainPage"
        ref={scrollRef}
        onWheel={e => {
          onWheel(e);
        }}
      >
        <div className="virtualScroll">
          <div className="play-ground" ref={moveRef}>
            <div className="main mainImg">
              <div className="logoImg">Million Ware</div>
              <div className="logoBG" ref={bgRef} />
            </div>
            <div className="main challenge">
              <h2>도전</h2>
              <h3>Challenge</h3>
            </div>
            <div className="main challenge _2">
              <h2>
                도전은 성공과 실패를 좌우하는 기준이 아니다.
                <br />
                경험과 창의력이 생김을 의미하는 것이다.
              </h2>
              <h3>
                Challenge is not a standard for measure success or failure.
                <br />
                Challenge means there are experience and creativeness.
              </h3>
            </div>
            <div className="main positive">
              <h2>긍정</h2>
              <h3>Positive</h3>
            </div>
            <div className="main positive _2">
              <h2>
                긍정은 부정의 반대말이 아니다.
                <br />
                적극적으로 해결해 나감을 의미하는 것이다.
              </h2>
              <h3>
                Positive is not a Antonym of Negative.
                <br />
                It means to solve problems actively.
              </h3>
            </div>
            <div className="main final">
              <img src={computer} alt="ssdd" />
              <div>
                <strong>밀리언웨어</strong>는<br />
                도전과 긍정이라는 핵심가치를 중심으로 하고,
                <br />
                기술 Trend에 능동적으로 대응 가능한
                <br />
                전문성 있는 기술력을 바탕으로 하여,
                <br />
                유연하고 강력한 서비스를 제공합니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
