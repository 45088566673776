import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import Tech from "./Pages/Tech";
import Culture from "./Pages/Culture";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";

const Routes = () => (
  <Switch>
    <Route path="/" exact>
      {({ match }) => <Home show={match !== null} />}
    </Route>
    <Route path="/Tech">{({ match }) => <Tech show={match !== null} />}</Route>
    <Route path="/Culture">
      {({ match }) => <Culture show={match !== null} />}
    </Route>
    <Route path="/Contact">
      {({ match }) => <Contact show={match !== null} />}
    </Route>
    <Route path="/Privacy">
      {({ match }) => <Privacy show={match !== null} />}
    </Route>
  </Switch>
);

export default Routes;
