import React, { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
// import { TweenMax } from "gsap/all";

// const startState = { autoAlpha: 0, x: -100 };

const Page = ({ children }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <Transition
      in={show}
      timeout={300}
      unmountOnExit
      onEnter={() => {
        // TweenMax.to(node, startState);
        // TweenMax.to(node, 5, {
        //   autoAlpha: 1,
        //   x: 0
        // });
      }}
    >
      {children}
    </Transition>
  );
};

export default Page;
