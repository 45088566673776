import React from "react";
import "./icons.less";
import Angular from "./angular.svg";
import C from "./C.svg";
import Csharp from "./csharp.svg";
import CSS3 from "./CSS3.svg";
import Docker from "./Docker.svg";
import Flutter from "./flutter.png";
import HTML5 from "./HTML5.svg";
import Java from "./Java.svg";
import JavaScript from "./JavaScript.svg";
import Jenkins from "./jenkins.png";
import JQuery from "./JQuery.svg";
import Nodejs from "./nodejs.svg";
import Python from "./Python.svg";
import ReactSvg from "./React.svg";
import Spring from "./spring.svg";
import SpringBoot from "./SpringBoot.jpg";
import Typescript from "./typescript_.svg";
import Vue from "./Vue.svg";

const IconTemplate = ({ className = "", iconRef, children }) => {
  return (
    <div className={`icon ${className}`} ref={iconRef}>
      {children}
    </div>
  );
};

export const AngularIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Angular />
  </IconTemplate>
);

export const CIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <C />
  </IconTemplate>
);

export const FlutterIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <img src={Flutter} alt="flutter" />
  </IconTemplate>
);

export const CsharpIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Csharp />
  </IconTemplate>
);
export const CSS3Icon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <CSS3 />
  </IconTemplate>
);
export const DockerIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Docker />
  </IconTemplate>
);
export const HTML5Icon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <HTML5 />
  </IconTemplate>
);
export const JavaIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Java />
  </IconTemplate>
);
export const JavaScriptIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <JavaScript />
  </IconTemplate>
);
export const JenkinsIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <img src={Jenkins} alt="flutter" />
  </IconTemplate>
);
export const JQueryIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <JQuery />
  </IconTemplate>
);
export const NodejsIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Nodejs />
  </IconTemplate>
);
export const PythonIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Python />
  </IconTemplate>
);
export const ReactIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef} className="react">
    <ReactSvg />
  </IconTemplate>
);
export const SpringIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Spring />
  </IconTemplate>
);
export const SpringBootIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <img src={SpringBoot} alt="flutter" />
  </IconTemplate>
);
export const TypescriptIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Typescript />
  </IconTemplate>
);
export const VueIcon = ({ iconRef }) => (
  <IconTemplate iconRef={iconRef}>
    <Vue />
  </IconTemplate>
);
