import React, { useCallback, useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { TweenMax, Linear } from "gsap/all";
import "./Menu.less";

import logo from "./images/logo.png";
import logo2 from "./images/logo_2.png";

const Menu = ({ history }) => {
  const [open, setOpen] = useState(false);
  const logoRef = useRef();
  const mylogo = open ? logo2 : logo;
  useEffect(() => {
    TweenMax.to(logoRef.current, 0, {
      rotation: -90,
      transformOrigin: "50% 0",
      left: 375,
      filter: "grayscale(100%)",
      "-webkit-filter": "grayscale(100%)",
      top: 100
    });
  }, []);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
    setTimeout(() => {
      if (!open) {
        TweenMax.to(logoRef.current, 0.5, {
          rotation: 0,
          transformOrigin: "50% 0",
          left: 10,
          top: 10,
          ease: Linear.easeIn,
          filter: "grayscale(0%)",
          "-webkit-filter": "grayscale(0%)"
        });
      } else {
        TweenMax.to(logoRef.current, 0.5, {
          rotation: -90,
          transformOrigin: "50% 0",
          left: 375,
          top: 100,
          ease: Linear.easeOut,
          filter: "grayscale(100%)",
          "-webkit-filter": "grayscale(100%)"
        });
      }
    });
  }, [logoRef, open]);
  const linkTo = useCallback(
    to => async () => {
      await history.push(to);
      toggleMenu();
    },
    [history, toggleMenu]
  );
  const logoHome = async () => {
    if (open) {
      await history.push("/");
      toggleMenu();
    }
  };

  return (
    <nav className={`menu ${open ? "open" : ""}`}>
      <img src={mylogo} ref={logoRef} onClick={logoHome} alt="" />
      <div className="navBtnWrap">
        <button type="button" onClick={toggleMenu} />
      </div>
      {open && (
        <>
          <ul className="gnb">
            <li>
              <span onClick={linkTo("/")}>whoWeAre</span>
            </li>
            <li>
              <span onClick={linkTo("/Tech")}>ourSkills</span>
            </li>
            <li>
              <span onClick={linkTo("/Culture")}>culture</span>
            </li>
            <li>
              <span onClick={linkTo("/Contact")}>recruit</span>
            </li>
            <li>
              <span onClick={linkTo("/Privacy")}>privacy</span>
            </li>
          </ul>
          <div className="contact">
            <ul>
              <li>(031) 633-0055 to Tel</li>
              <li>(031) 624-3453 to Fax</li>
              <li>Millionware@gmail.com</li>
              <li>주식회사 밀리언웨어</li>
              <li>경기도 이천시 부발읍 경충대로 2039, 상가동 301호</li>
            </ul>
          </div>
          <p className="copy">
            Copyright 2019. Millionware Inc. All rights reserved.
          </p>
        </>
      )}
    </nav>
  );
};

export default withRouter(Menu);
