import React, { useRef, useEffect } from "react";
import {
  CSSPlugin,
  RoundPropsPlugin,
  BezierPlugin,
  DirectionalRotationPlugin,
  AttrPlugin
} from "gsap/all";

import * as Icons from "../icons";
import "./Tech.less";
import Page from "./Page";

// eslint-disable-next-line no-unused-vars
const plugins = [
  CSSPlugin,
  RoundPropsPlugin,
  BezierPlugin,
  DirectionalRotationPlugin,
  AttrPlugin
];

const fx = (w, h) => x => {
  if (x > w) return 0;
  return Math.sqrt(h ** 2 * (1 - (x * 2 - w) ** 2 / w ** 2));
};

const animation = (w, h, refList) => {
  const fxx = fx(w / 2, h / 2);
  return progress => {
    refList.forEach((ref, index) => {
      if (ref.current) {
        const rRog = (progress + index / refList.length) % 1;
        const x = rRog * w;
        const y = fxx(x);
        // eslint-disable-next-line no-param-reassign
        ref.current.style.transform = `translate3d(${x * 2 - 100}px, ${h -
          y +
          100}px,0px) scale( ${rRog ** 2 + 1})`;
      }
    });
  };
};

let ani;

const Tech = () => {
  const scrollRef = useRef();
  const refList = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef()
  ];

  useEffect(() => {
    // console.log(animation);
    const { clientHeight, clientWidth } = scrollRef.current;
    ani = animation(clientWidth, clientHeight, refList);
    ani(0);
  }, [scrollRef, refList]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      ani(scrollTop / (scrollHeight - clientHeight));
    }
  };

  return (
    <div className="page Tech" ref={scrollRef} onScroll={onScroll}>
      <div className="scroll-ground" />
      <div className="play-ground">
        {/* Tech */}
        <Icons.ReactIcon iconRef={refList[0]} />
        <Icons.SpringBootIcon iconRef={refList[1]} />
        <Icons.SpringIcon iconRef={refList[2]} />
        <Icons.PythonIcon iconRef={refList[3]} />
        <Icons.CsharpIcon iconRef={refList[4]} />
        <Icons.CIcon iconRef={refList[5]} />
        <Icons.AngularIcon iconRef={refList[6]} />
        <Icons.FlutterIcon iconRef={refList[7]} />
        <Icons.CSS3Icon iconRef={refList[8]} />
        <Icons.DockerIcon iconRef={refList[9]} />
        <Icons.HTML5Icon iconRef={refList[10]} />
        <Icons.JavaIcon iconRef={refList[11]} />
        <Icons.JavaScriptIcon iconRef={refList[12]} />
        <Icons.JenkinsIcon iconRef={refList[13]} />
        <Icons.JQueryIcon iconRef={refList[14]} />
        <Icons.NodejsIcon iconRef={refList[15]} />
        <Icons.TypescriptIcon iconRef={refList[16]} />
        <Icons.VueIcon iconRef={refList[17]} />
      </div>
    </div>
  );
};

const TachPage = () => (
  <Page>
    <Tech />
  </Page>
);
export default TachPage;
