import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Menu from "./Menu";
import "./App.less";
import Routes from "./Routes";

const App = () => {
  return (
    <Router>
      <Menu />
      <Routes />
    </Router>
  );
};

export default App;
