import React from "react";
import Page from "./Page";
import "./Culture.less";
import img1 from "../images/easter_egg.png";
import img2 from "../images/high_five.png";
import img3 from "../images/ticket.png";
import img4 from "../images/test.png";
import img5 from "../images/mw_ico.png";
import img6 from "../images/hands.png";
import img7 from "../images/dinner.png";
import img8 from "../images/recruitment.png";
import img9 from "../images/taxi.png";

const items = [
  {
    num: 1,
    title: "이스트에그",
    src: img1,
    txt: "새로운것을 만들때 옆에 있는<br />든든한 동료들"
  },
  {
    num: 2,
    title: "팀회식 및 워크샵",
    src: img2,
    txt: "더욱 더 돈독한<br>시간이 될수 있기를"
  },
  {
    num: 3,
    title: "장기근속 휴가 & 지원금",
    src: img3,
    txt: "오래 일한 당신에게<br>특별한 포상을!"
  },
  {
    num: 4,
    title: "종합 건강검진 지원",
    src: img4,
    txt: "당신은<br>소중하니깐요"
  },
  {
    num: 5,
    title: "",
    src: img5,
    txt: ""
  },
  {
    num: 6,
    title: "경조사 지원",
    src: img6,
    txt: "기쁨은 두 배,<br>슬픔은 절반!"
  },
  {
    num: 7,
    title: "저녁식대 지원",
    src: img7,
    txt: "늦은 시간이지만<br>맛있게 먹고 힘내세요!"
  },
  {
    num: 8,
    title: "사내추천제도",
    src: img8,
    txt: "추천 채용 성공 시<br>연말에 특별선물을~"
  },
  {
    num: 9,
    title: "야근시 택시비 지원",
    src: img9,
    txt: "늦은 시간에는<br>안전귀가 보장!"
  }
];
const Culture = ({ show }) => {
  const renderItem = () => {
    return items.map(obj => {
      // console.log(items);

      return (
        <div key={obj.num} className="cultureItem">
          <figure>
            <img src={obj.src} alt={obj.title} />
            <figcaption>{obj.title}</figcaption>
          </figure>
          <span dangerouslySetInnerHTML={{ __html: obj.txt }}></span>
        </div>
      );
    });
  };
  return (
    <Page show={show}>
      <div className="page">
        <div className="cultureWrap">
          <div className="cultureBox">{renderItem()}</div>
        </div>
      </div>
    </Page>
  );
};

export default Culture;
