import React from "react";
import Page from "./Page";
import "./Contact.less";
import recImg from "../images/Recruit_content_01.png";
import Imgjk from "../images/img_jk.jpg";
import Imgsi from "../images/img_si.jpg";

// const chkVal = [
//   { key: "C", val: 1 },
//   { key: "C#", val: 2 },
//   { key: "Java", val: 3 },
//   { key: "Javascript", val: 4 },
//   { key: "PHP", val: 5 },
//   { key: "Python", val: 6 },
//   { key: "React.js", val: 7 },
//   { key: "Django", val: 8 },
//   { key: "Vue.js", val: 9 },
//   { key: "CSS", val: 10 },
//   { key: "Spring", val: 11 },
//   { key: "SpringBoot", val: 12 },
//   { key: "Docker", val: 13 },
//   { key: "Flutter", val: 14 },
//   { key: "NodeJS", val: 15 },
//   { key: "AngularJS", val: 16 },
//   { key: "HTML5", val: 17 }
// ];
const LinkJK =
  "http://www.jobkorea.co.kr/Search/?stext=밀리언웨어&tabType=recruit&Page_No=1";
const LinkSI =
  "http://www.saramin.co.kr/zf_user/search/recruit?company_cd=0,1,2,3,4,5,6,7,9&searchType=search&searchword=밀리언웨어";
const Contact = ({ show }) => {
  // const renderChkBox = () => {
  //   return chkVal.map(obj => {
  //     return (
  //       <div key={obj.val}>
  //         <label>
  //           <input type="checkbox" value={obj.val} />
  //           {obj.key}
  //         </label>
  //       </div>
  //     );
  //   });
  // };
  return (
    <Page show={show}>
      <div className="page">
        <div className="contactWrap">
          <h1>Recruit</h1>
          <div className="contactNotice">
            <p>
              <img src={recImg} />
            </p>
            <div>
              <h2>
                밀리언웨어와 미래를 함께 할 <strong>열정있는 인재</strong>를
                모집합니다.
              </h2>
            </div>
          </div>
          <p className="linkWrap">
            <a href={LinkJK} target="_blank">
              <img src={Imgjk} />
            </a>
            <a href={LinkSI} target="_blank">
              <img src={Imgsi} />
            </a>
          </p>
          {/* <div className="contactFrm">
            <form action="">
              <fieldset>
                <div>
                  <h2>활용가능 기술</h2>
                  <div className="skillWrap">{renderChkBox()}</div>
                </div>
                <div>
                  <h2>연락처</h2>
                  <div>
                    <input
                      type="tel"
                      placeholder="휴대폰 번호를 입력해주세요"
                    />
                  </div>
                </div>
                <div>
                  <h2>전할 말</h2>
                  <div>
                    <textarea />
                  </div>
                </div>
              </fieldset>
            </form>
          </div> */}
        </div>
      </div>
    </Page>
  );
};

export default Contact;
